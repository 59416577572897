 

 
 
a {
  color: #70757a;
  text-decoration: none;
}

/* #0b8043 */

.fc-daygrid-event-harness {
  /* background-color: #0B8043; */
  /* color: white; */
  /* border: none; */
  /* border-radius: 15px; */

  margin-top: 8px;
  /* margin-left: 2px; */
  /* padding: 2px; */
    font-family: Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .3px;
    text-align: center;
    white-space: nowrap;
    width: max-content;
    min-width: 24px;
    color: var(--on-surface-variant-agm);
    /* color: #ee1400; */
    line-height: 16px;
    pointer-events: auto;

}

.fc-daygrid-event-harness:hover {
  /* background-color: #0b8043; */
  /* color: #ff1904; */
  cursor: pointer;
}

.fc {
  font-family: 'Roboto', Arial, sans-serif;
}

.fc-header-toolbar {
  background-color: white;
  border: none;
  display: none;
} 

.fc-event-title {
  font-weight: 600;
}

.fc-event-time {
  font-size: 14px;
}

.fc-button {
  color: #202124;
  background-color: red;
  border: 21px solid red;
  border-radius: 94px;
  display: none;
}

.fc-button:hover {
  background-color: #f1f3f4;
  display: none;
}

.fc-today-button {
  background-color: #1badf6;
  color: #202124;
  display: none;
}

.fc-today {
  background-color: #e1f4e9 !important;
  display: none;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: -10px;
}